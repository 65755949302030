<template>
    <div>
      <div v-if="classRoom">
        <div class="row">
          <div class="col-lg-12">
            <div>
              <!-- <div class="dk_icon" style="width: 10%">
        
        </div> -->
              <div class="dispflex" style="padding-bottom: 0px">
                <!-- <span class="has-float-label" >
            <select
            @change="getStudentAttendanceList"
              class="form-control form-input"
              id="deptlistid"
              v-model="selectTerm"
            >
              <option :value="null">-- Select--</option>
              <option
                v-for="(item, index) in termList"
                :key="index"
                :value="item._id"
              >
                {{ item.name }}
              </option>
            </select>
            <label for="clslistid">Select Term </label>
          </span> -->
                <span class="has-float-label" style="width: 11%">
                  <input
                    type="date"
                    @change="getStudentAttendanceList"
                    :disabled="!selectClass || !selectSection"
                    v-model="selectDate"
                    :min="minDate"
                    :max="maxDate"
                    class="form-control form-input"
                    placeholder="Search Students"
                  />

                  <label for="clslistid">Select Date </label>
                </span>
                <span class="has-float-label" style="width: 11%">
                  <input
                  
                    v-model="searchStudentWords"
                    class="form-control form-input"
                  />

                  <label for="clslistid">Search Student </label>
                </span>

                
                <div style="margin-top: 10px" class="flexitm">
                </div>

                <div class="text-right dk_iconsml widjetcontent">
                 
                  <button
                    v-if="!isDailyAttendance"
                    style="width: 100%; margin-left: 6px"
                    type="button"
                    @click="updateDailyAttendance"
                    class="btn btnsml"
                    id="svbtn"
                  >
                    <span>Update</span>
                  </button>
                  <button
                    v-if="isDailyAttendance"
                    style="width: 100%; margin-left: 6px"
                    type="button"
                    @click.prevent="generateandApprove"
                    class="btn btnsml"
                    id="svbtn"
                  >
                    <span>Save</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="!isDailyAttendance">
          <div class="col-lg-12">
            <div class="widjet">
              <div class="widjetcontent">
                <div
                  class="countsect p-2 mb-3"
                  style="border-radius: 0rem"
                >
                  <ul class="dispflex">
                    <li>
                      <span class="badge contbadge">{{
                        studentAttendanceList.length
                      }}</span
                      >Total Students
                    </li>
                    <li>
                      <span class="badge contbadge">{{
                        getPresentCount(studentAttendanceList)
                      }}</span
                      >Present
                    </li>
                    <li>
                      <span class="badge contbadge">{{
                        getAbsentCount(studentAttendanceList)
                      }}</span
                      >Absent
                    </li>
                    <!-- <li>
                                  <span class="badge contbadge">{{
                                    getLeaveCount(studentAttendanceList)
                                  }}</span
                                  >Leave
                                </li> -->
                  </ul>
                </div>
                <div>
                  <div class="restable">
                    <div class="resrow resheader">
                      <div class="cell">Student Name</div>
                      <div class="cell">Present/Absent</div>
                      <div class="cell">Status</div>

<!-- <div class="cell">Present/Absent</div> -->
<div class="cell">Half Day</div>
                      <!-- <div class="cell">Status</div> -->
                      <div class="cell">Leave</div>

                      <!-- <div class="cell">Action</div> -->
                    </div>
                    <div
                      class="resrow"
                      v-for="(student, index) in studentAttendanceList"
                      :key="index"
                    >
                      <div class="cell" data-title="Student Name">
                        {{ student.firstName + " " + student.lastName }}
                        <!-- <a style="padding-left: 6px;" href="javascript:void(0);"  @click.prevent="redirectRoute(`/admission/student/view/details/${student.student}`)">{{ student.firstName + " " + student.lastName }} </a> -->

                        <!-- {{
                                      student.firstName + " " + student.lastName
                                    }} -->
                      </div>

                      <div
                        class="cell"
                        data-title="Student Name"
                        :style="
                          student.status == 'Present'
                            ? 'color: lawngreen;'
                            : 'color: red;'
                        "
                      >
                     
                        <div class="cell">
                          <label class="switch">
                            <input
                              type="checkbox"
                              id="togBtn"
                              v-model="student.isPresent"
                            />
                            <div class="slider"></div>
                          </label>
                        </div>
                       
                        <!-- <span class="has-float-label">
                                      <select
                                        name="gender"
                                        class="form-control form-input"
                                        id="gender"
                                        v-model="student.status"
                                      >
                                        <option :value="null">-- Select --</option>
                                        <option
                                          v-for="(item, index) in attendanceStatus"
                                          :key="index"
                                          :value="item"
                                        >
                                          {{ item }}
                                        </option>
                                      </select>
                                     
                                    </span> -->
                      </div>
                      <div
                        class="cell"
                        data-title="Student Name"
                        :style="
                          student.status == 'Present'
                            ? 'color: lawngreen;'
                            : 'color: red;'
                        "
                      >
                        {{
                                      student.status == "Present"
                                        ? "Present"
                                        : student.isHalfDay ? 'Half Day' : "Absent"
                                    }}
                                     </div>
                                     <div class="cell" data-title="Teacher">
                        <div class=" chkbox" data-title="Select">
                  <label class="custcheckbox">
                    <input type="checkbox" v-model="student.isHalfDay"  />
                    <span class="checkmarkchk" style="top: -28px;"></span>
                    
                  </label>
                </div>
                      </div>
                      <div class="cell" data-title="Student Name">
                        {{ student.status == "Leave" ? "Yes" : "-" }}
                      </div>
                      

                      <!-- <div class="cell" data-title="Student Name">
                                    <button
                                      class="btn round orange tooltipt"
                                      v-b-modal.addacademicyr
                                      @click="showUpdateModal(student)"
                                    >
                                      <i class="fas fa-edit"></i
                                      ><span class="tooltiptext">Edit</span>
                                    </button>
                                  </div> -->

                      <!-- <div class="cell" data-title="Action" >
                          <button class="btn round tooltipt"  :disabled="student.parent && student.parent.isPasswordVerified" style="background-color: darkcyan;" @click.prevent="inviteParent(index)">
                          <i class="fas fa-chalkboard-teacher" style="padding-left:4px;"></i>
                          <span v-if="student.parent && !student.parent.isPasswordVerified" class="tooltiptext">Invite Parent</span>
                          <span v-else class="tooltiptext">Invited</span>
                        </button>
                        <button class="btn round orange tooltipt" @click.prevent="editStudentModal(index)">
                          <i class="fas fa-edit"></i><span class="tooltiptext">Edit</span>
                        </button>
                          <button class="btn round red tooltipt" @click="showDeleteModal(student._id)"  >
                            <i class="far fa-trash-alt"></i> <span class="tooltiptext">Delete</span>
                          </button>
                      </div> -->
                    </div>
                  </div>
                </div>
                <div
                  class="crtfeeplancont"
                  style="padding: 0 13px"
                  v-if="studentAttendanceList.length == 0"
                >
                  <div style="margin-top: 10%; text-align: center">
                    No Data Found
                  </div>
                </div>
                <!-- <div class="crtfeeplancont" style="padding: 0 13px" v-if="!isAdmin">
                              <div style="margin-top: 10%;text-align: center;">
                                Only Class admin can take attendenace
                              </div>
                            </div> -->
              </div>
              <!-- <div class="widjetcontent"> -->
              <!-- <div style="margin-top: 10%;text-align: center;">
                                sdsdsdsd  sds 
                              </div>
                          </div> -->
            </div>
          </div>
        </div>
        <div v-if="isDailyAttendance">
          <form id="stuprofile" accept-charset="utf-8">
            <div class="form-row">
              <div class="form-group col-md-12">
                <div class="tbllist">
                  <div class="restable">
                    <div class="resrow resheader">
                      <div class="cell">Student Name</div>

                      <div class="cell">Date</div>
                      <div class="cell">Status</div>

                      <div class="cell">Present/Absent</div>
                      <div class="cell">Half Day</div>

                      <!-- <div class="cell">Action</div> -->
                    </div>
                    <div
                      class="resrow"
                      v-for="(data, index) in studentAttendanceDetails"
                      :key="index"
                    >
                      <div class="cell" data-title="Teacher">
                        {{ data.firstName + " " + data.lastName }}
                      </div>
                      <div class="cell" data-title="Teacher">
                        {{ data.attendanceDate }}
                      </div>
                      <div class="cell" data-title="Teacher">
                        {{ data.status || '-' }}
                      </div>
                      <div class="cell" data-title="Teacher">
                        <div class="cell">
                          <label class="switch">
                            <input
                              type="checkbox"
                              id="togBtn"
                              v-model="data.isPresent"
                            />
                            <div class="slider"></div>
                          </label>
                        </div>

                        <!-- <select
                            class="form-control form-input"
                            v-model="data.status"
                            :disabled="data.status == 'Leave' && data._id"
                          >
                            <option :value="null">--Select--</option>
                            <option
                              v-for="(item, index) in attendanceStatus"
                              :key="index"
                              :value="item"
                            >
                              {{ item }}
                            </option>
                          </select> -->
                        <!-- {{ data.isAbsent ? "Present" : "Absent" }} -->
                      </div>
                      <div class="cell" data-title="Teacher">
                        <div class=" chkbox" data-title="Select">
                  <label class="custcheckbox">
                    <input type="checkbox"  v-model="data.isHalfDay" />
                    <span class="checkmarkchk" style="top: -28px;"></span>
                    
                  </label>
                </div>
                      </div>

                      <!-- <div class="cell" data-title="Teacher">
                          <button
                            @click.prevent="confirmDelete(data._id, data.index)"
                            class="btn round red tooltipt"
                          >
                            <i class="far fa-trash-alt"></i>
                            <span class="tooltiptext">Delete</span>
                          </button>
                        </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="mt-3 pt-3"
              style="border-top: 1px solid #e5e5e5"
            >
              <!-- <div class="dk_icon"></div> -->

              <div class="text-center dk_iconsml">
                <!-- <button
                    type="button"
                    class="btn btnsml"
                    id="svbtn"
                    @click.prevent="generateandApprove"
                  >
                      <span>Save</span>
                  </button>
                  <button
                    type="button"
                    class="btn btncl clsmdl"
                    id="clbtn"
                    @click="closeAttendancePopUp"
                  >
                       <span>Cancel</span>
                  </button> -->
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="widjet" v-else style="height: 235px;">
          <div style="padding-top: 92px;" >
            <!-- <span style="margin-left: 42%;" v-if="isLoader">Select Class And ClassRoom</span> -->
            <span style="margin-left: 42%;">Please Select Classroom...</span>
          </div>
        </div>
  </div>
</template>
<script>
import Select from "vue-select";
import ViService from "@/services/ViService";
import secureUI from "../../../utils/secureUI";

export default {
  components: {
    "v-select": Select,
  },
  props: {
    classId: {
      type: String,
    },
    classRoom: {
      type: String,
    },
    assignedSubjects: {
      type: Array,
    },
  },
  data() {
    return {
      isAdmin: false,
      userDetail: {},
      sectionList: [],
      selectTerm: null,
      termList: [],
      overallSectionList: [],
      overallClassList: [],
      overAllPreviousMonths: [],
      overallSelectedClass: null,
      overallSelectedSection: null,
      overallSelectMonth: null,
      deleteId: null,
      previousMonths: [],
      minimumDate: null,
      selectedMonth: null,
      attendanceList: [],
      studentAttendanceDetails: [],
      showOption: false,
      staffName: "",
      staffList: [],
      studentAttendanceList: [],
      overallAttendanceList: {},
      selectedStaff: null,
      dates: [],
      skip: 0, // set the skip info
      limit: 5000, // set the limit info
      currentPageNumber: 1,
      lastPageNumber: 1,
      selected: 1,
      countAll: true,
      totalCount: [],
      minimumDate: null,
      attendance: {},
      attendanceStatus: ["Present", "Absent", "Leave"],
      selectedIndex: null,
      clockInTime: "",
      clockOutTime: "",
      selectClass: null,
      selectSection: null,
      selectDate: null,
      searchStudentName: "",
      classList: [],
      isDailyAttendance: false,
      studentCount: 0,
      updateAttendanceDetails: {},
      searchWords: "",
      searchStudentWords: '',
      showLimitCount: 30,
      allOverallAttendanceList: {},
      minDate: null,
      maxDate: null
    };
  },

  created() {
    this.initComponent();
   // this.getPreviousMonths();
    //this.formDateList();
   

  },
  watch: {
    searchWords: function (currentVal, oldVal) {
      //  let hasCurrentParam = currentVal || '';
      //  let hasOldParam = oldVal || '';

      // if (hasCurrentParam != hasOldParam) {
      //      this.skip = 0
      //      this.currentPageNumber = 1,
      //      this.lastPageNumber = 1,
      //      this.selected = 1,
      //      this.totalCount = [],
      //      this.countAll = true,
      this.getOverallAttendanceList();
      // }
    },
    searchStudentWords: function (currentVal, oldVal) {
   
      this.getStudentAttendanceList();
      // }
    },
  },
  computed: {
    styles() {
      var brwHeight = window.innerHeight;
      return {
        height: brwHeight - 90 + "px",
      };
    },
    optionStaffList() {
      return this.staffList.map((x) => {
        return {
          label: x.firstName + " " + x.lastName + " / " + x.userId,
          value: x._id,
        };
      });
    },
  },
  methods: {
    async getClassTerm() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viXGet(
          `/class/getAllTerm?classId=${this.classId}`,
          userData.token
        );
        if (response.isSuccess) {
          let data = secureUI.secureGet(response.data);
          this.termList = data || [];

          if (this.termList.length > 0) {
            if (this.termList.length == 1) {
            this.selectTerm = this.termList[0]._id;
            //this.getCurriculumInfo(this.termList[0]._id,null);

            var startDate = new Date(this.termList[0].startDate);
            var endDate = new Date(this.termList[0].endDate);

            this.minDate = this.dateFormat(startDate)
        
            this.maxDate =  this.dateFormat(endDate)
            }else{
            let currentDate = new Date()

            let checkData = this.termList.filter( x => (currentDate >= new Date(x.startDate)) && (currentDate <= new Date(x.endDate)))
            if(checkData.length > 0){

              this.selectTerm = checkData[0]._id;
             // this.getCurriculumInfo(checkData[0]._id,null);

              var startDate = new Date(checkData[0].startDate);
              var endDate = new Date(checkData[0].endDate);

              this.minDate = this.dateFormat(startDate)
          
              this.maxDate =  this.dateFormat(endDate)

            }else{
              let checkfutureDates = this.termList.filter( x => (new Date(x.startDate) > currentDate))
             if(checkfutureDates.length > 0){
              this.selectTerm = checkfutureDates[0]._id;
             // this.getCurriculumInfo(checkfutureDates[0]._id,null);

              var startDate = new Date(checkfutureDates[0].startDate);
              var endDate = new Date(checkfutureDates[0].endDate);

              this.minDate = this.dateFormat(startDate)
          
              this.maxDate =  this.dateFormat(endDate)
             }else{
              this.selectTerm = this.termList[0]._id;
              //this.getCurriculumInfo(this.termList[0]._id,null);

              var startDate = new Date(this.termList[0].startDate);
              var endDate = new Date(this.termList[0].endDate);

              this.minDate = this.dateFormat(startDate)
          
              this.maxDate =  this.dateFormat(endDate)
            }
            }
           
            }
          }
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }

     // this.$bvModal.show("viewSectionPopUp");
    },
    dateFormat(value){
      if (value) {
        let date = new Date(value);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2,"0"); // Months are zero-based, so we add 1
        const day = String(date.getDate()).padStart(2, "0");

        return `${year}-${month}-${day}`;
    }
    },
    async navToSelectedPage(selectedPageNo) {
      let start = null;
      let end = null;

      if (selectedPageNo != 1) {
        let curent = selectedPageNo + 1;
        let newStartNumber = this.showLimitCount * (curent - 1);
        start = newStartNumber - this.showLimitCount;
        end = newStartNumber;
      } else {
        start = 0;
        end = this.showLimitCount;
      }

      let dataLength = Object.entries(this.allOverallAttendanceList).length;
      if (end > dataLength) {
        end = dataLength;
      }
      if (start >= dataLength) {
        start = start - 1;
      }

      var result = Object.entries(this.allOverallAttendanceList);

      let getCurrentPageData = result.slice(start, end);
      const obj = Object.fromEntries(getCurrentPageData);
      this.overallAttendanceList = obj;
    },

    redirectRoute(vipath) {
      if (this.$route.path !== vipath) this.$router.push({ path: vipath });
    },
    getTotalPresent(data) {
      if (data) {
        let details = this.overallAttendanceList[data];
        let count = details.filter((x) => x.status == "Present");
        if (count) {
          return count.length;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
    getTotalAbsent(data) {
      if (data) {
        let details = this.overallAttendanceList[data];
        let count = details.filter(
          (x) => x.status == "Absent" || x.status == "Leave"
        );
        if (count) {
          return count.length;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
    getTotalDays(data) {
      if (data) {
        let details = this.overallAttendanceList[data];
        //  let count = details.filter((x) => x.status == "Absent" || x.status == "Leave");
        if (details) {
          return details.length;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
    getTotalLeave(data) {
      if (data) {
        let details = this.overallAttendanceList[data];
        let count = details.filter((x) => x.status == "Leave");
        if (count) {
          return count.length;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
    getPresentCount(data) {
      if (data.length > 0) {
        let count = data.filter((x) => x.status == "Present");
        if (count) {
          return count.length;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
    getAbsentCount(data) {
      if (data.length > 0) {
        let count = data.filter(
          (x) => x.status == "Absent" || x.status == "Leave"
        );
        if (count) {
          return count.length;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
    async initComponent() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        this.userDetail = userData.userDetails;
      }

      await this.getClassTerm();
      await this.getClassWithSection();
    },
    checkIsAdminTeacher(sectionId) {
      let userData = secureUI.sessionGet("user");

      if (userData.userDetails.profile.name == "Teacher") {
        let selectedSection = this.sectionList.find((x) => x._id == sectionId);

        if (selectedSection.assignedTeachers.length > 0) {
          let checkCreateAttendance = selectedSection.assignedTeachers.filter(
            (x) => x.teacherId == this.userDetail._id && x.isAdmin
          );

          if (checkCreateAttendance.length > 0) {
            this.isAdmin = true;
          } else {
            this.isAdmin = false;
          }
        } else {
          this.isAdmin = false;
        }
      } else {
        this.isAdmin = true;
      }

      this.selectDate = null;
      this.studentAttendanceList = [];
    },
    getSection(classId) {
      this.selectSection = null;

      this.studentAttendanceList = [];
      //  :disabled="!selectClass || !selectSection"

      //this.assignedSubjects = []
      let sections = this.classList.find((x) => x._id == classId).sections;

      let userData = secureUI.sessionGet("user");

      if (userData.userDetails.profile.name == "Teacher") {
        this.sectionList = sections.filter(
          (x) => x.assignedTeachers.length > 0
        );
      } else {
        this.sectionList = sections;
      }

      if (this.sectionList.length > 0) {
        this.selectSection = this.classRoom;
        this.checkIsAdminTeacher(this.selectSection);
        //this.getClassTerm()
      }

      if (!this.selectDate) {
        let start = new Date();

        let startmonth = start.getMonth() + 1;
        let startdate = start.getDate();
        let startYear = start.getFullYear();

        if (startdate < 10) {
          startdate = "0" + startdate;
        }
        if (startmonth < 10) {
          startmonth = "0" + startmonth;
        }
        this.selectDate = startYear + "-" + startmonth + "-" + startdate;
        this.getStudentAttendanceList();
      }
    },
    getOverAllSection(classId) {
      this.overallSelectedSection = null;
      this.overallSectionList = [];
      let data = this.overallClassList.filter((x) => x._id == classId);
      if (data.length > 0) {
        let userData = secureUI.sessionGet("user");

        if (userData.userDetails.profile.name == "Teacher") {
          this.overallSectionList = data[0].sections.filter(
            (x) => x.assignedTeachers.length > 0
          );
        } else {
          this.overallSectionList = data[0].sections;
        }
      }

      this.getOverallAttendanceList();
    },

    async getClassWithSection() {
      let userData = secureUI.sessionGet("user");

      if (userData.userDetails.profile.name == "Teacher") {
        const response = await ViService.viXGet(
          "/teacher/getAssignedTeacherwithClassSectionList?viewType=list",
          userData.token
        );

        if (response.isSuccess) {
          const resultData = secureUI.secureGet(response.data);
          this.classList = resultData.data;
          this.overallClassList = resultData.data;
        } else {
          this.$toasted.error(response.message);
        }
      } else {
        const response = await ViService.viXGet(
          `/academic/class_with_sections`,
          userData.token
        );

        if (response.isSuccess) {
          const resultData = secureUI.secureGet(response.data);
          this.classList = resultData.data;
          this.overallClassList = resultData.data;
        } else {
          this.$toasted.error(response.message);
        }
      }

      if (this.classList.length > 0) {
        this.selectClass = this.classId;
        await this.getSection(this.selectClass);
      }
    },
    openModal(data) {
      this.minimumDate = null;
      // this.maxmimumDate = null

      let tempAttendanceList = [...this.attendanceList];

      if (
        this.getTimeValuesByClockInDate(tempAttendanceList, data.createdDate)
      ) {
        const targetDateTime = new Date(data.createdDate).setHours(0, 0, 0, 0);

        for (const obj of tempAttendanceList) {
          const createdDateTime = new Date(obj.clockInTime).setHours(
            0,
            0,
            0,
            0
          );
          if (createdDateTime === targetDateTime) {
            this.attendance = obj;

            var clockInDate = new Date(this.attendance.clockInTime);
            var clockInYear = clockInDate.getFullYear();
            var clockInMonth = String(clockInDate.getMonth() + 1).padStart(
              2,
              "0"
            ); // Add 1 to month since it is zero-based
            var clockInDay = String(clockInDate.getDate()).padStart(2, "0");
            var clockInHour = String(clockInDate.getHours()).padStart(2, "0");
            var clockInMinute = String(clockInDate.getMinutes()).padStart(
              2,
              "0"
            );
            //var clockInSecond = String(clockInDate.getSeconds()).padStart(2, '0');

            this.attendance.clockInTime = `${clockInYear}-${clockInMonth}-${clockInDay}T${clockInHour}:${clockInMinute}`;

            if (this.attendance.clockOutTime) {
              var clockOutDate = new Date(this.attendance.clockOutTime);
              var clockOutYear = clockOutDate.getFullYear();
              var clockOutMonth = String(clockOutDate.getMonth() + 1).padStart(
                2,
                "0"
              ); // Add 1 to month since it is zero-based
              var clockOutDay = String(clockOutDate.getDate()).padStart(2, "0");
              var clockOutHour = String(clockOutDate.getHours()).padStart(
                2,
                "0"
              );
              var clockOutMinute = String(clockOutDate.getMinutes()).padStart(
                2,
                "0"
              );

              this.attendance.clockOutTime = `${clockOutYear}-${clockOutMonth}-${clockOutDay}T${clockOutHour}:${clockOutMinute}`;
              //  /// var clockOutFormattedDate = `${year}-${month}-${day}T${'20'}:${'00'}`;
              //  this.minimumDate = this.attendance.clockOutTime
            }
          }
        }

        //   const date = new Date(data.createdDate);
        //     const year = date.getFullYear();
        //     const month = String(date.getMonth() + 1).padStart(2, '0'); // Add 1 to month since it is zero-based
        //     const day = String(date.getDate()).padStart(2, '0');
        //   // var hours = String(date.getHours()).padStart(2, '0');
        //   //var minutes = String(date.getMinutes()).padStart(2, '0');
        //   const clockInFormattedDate = `${year}-${month}-${day}T${'00:59'}`;
        //  /// var clockOutFormattedDate = `${year}-${month}-${day}T${'20'}:${'00'}`;
        //   this.minimumDate = clockInFormattedDate
      } else {
        const date = new Date(data.createdDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Add 1 to month since it is zero-based
        const day = String(date.getDate()).padStart(2, "0");
        // var hours = String(date.getHours()).padStart(2, '0');
        //var minutes = String(date.getMinutes()).padStart(2, '0');
        const clockInFormattedDate = `${year}-${month}-${day}T${"00:59"}`;
        /// var clockOutFormattedDate = `${year}-${month}-${day}T${'20'}:${'00'}`;
        this.minimumDate = clockInFormattedDate;
      }

      this.$bvModal.show("modalOne");
    },
    cancelModal() {
      this.$bvModal.hide("modalOne");
    },
    async updateAttendance() {
      try {
        let isFormValid = false;

        await this.$validator.validateAll("modalOne").then((result) => {
          isFormValid = result;
        });

        if (isFormValid) {
          let userData = secureUI.sessionGet("user");

          if (!userData) {
            this.$toasted.error("Please login and do the action");
            this.$router.push("/login");
          } else {
            const response = await ViService.viXPut(
              `/user/attendance/regularize`,
              this.attendance,
              userData.token
            );
            if (response.isSuccess) {
              this.attendance = {};
              this.$bvModal.hide("modalOne");
              this.$toasted.success("Regularize Updated Successfully");
              this.getAttendanceList();
            } else {
              this.$toasted.error(response.message);
            }
          }
        }
      } catch (err) {
        this.$toasted.error(
          err && err.message ? err.message : "Internal Server Error"
        );
      }
    },
    async formDateList() {
      const currentDate = new Date();
      const firstDayOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      ); // Get the first day of the month

      this.currentMonth = currentDate.getMonth() + 1;

      while (currentDate >= firstDayOfMonth) {
        //  if(currentDate.getDay() == 0){
        const formattedDate = currentDate.toLocaleString("en-US", {
          weekday: "long",
          month: "long",
          day: "numeric",
        }); // Format the date as "Monday - May 29"

        const currentYear = currentDate.getFullYear();
        const currentMonth = String(currentDate.getMonth() + 1).padStart(
          2,
          "0"
        ); // Months are zero-based, so we add 1
        const currentDay = String(currentDate.getDate()).padStart(2, "0");
        // if (new Date(formattedDate).getDay() != 1) {
        this.dates.push({
          date: formattedDate,
          createdDate: `${currentYear}-${currentMonth}-${currentDay}`,
          clockInTime: "", // Replace with the actual clockInTime value
          clockOutTime: "", // Replace with the actual clockOutTime value
        }); // Add the formatted date and other data to the array
        // }

        currentDate.setDate(currentDate.getDate() - 1); // Decrement the date by one day
      }

      //}
    },
    async changeDateList(selectedMonth) {
      this.dates = [];

      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1;
      const month = selectedMonth + 1;
      if (month == currentMonth) {
        this.formDateList();
      } else {
        const firstDayOfMonth = new Date(
          currentDate.getFullYear(),
          selectedMonth,
          1
        ); // Get the first day of the month

        var lastDay = new Date(currentDate.getFullYear(), month, 0);

        while (lastDay >= firstDayOfMonth) {
          const formattedDate = lastDay.toLocaleString("en-US", {
            weekday: "long",
            month: "long",
            day: "numeric",
          }); // Format the date as "Monday - May 29"

          const currentYear = lastDay.getFullYear();
          const currentMonth = String(lastDay.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so we add 1
          const currentDay = String(lastDay.getDate()).padStart(2, "0");
          //if (new Date(formattedDate).getDay() != 1) {
          this.dates.push({
            date: formattedDate,
            createdDate: `${currentYear}-${currentMonth}-${currentDay}`,
            clockInTime: "", // Replace with the actual clockInTime value
            clockOutTime: "", // Replace with the actual clockOutTime value
          }); // Add the formatted date and other data to the array
          // }

          lastDay.setDate(lastDay.getDate() - 1); // Decrement the date by one day
        }
      }

      this.getStaffAttendanceList();
    },
    showDeleteModal(id) {
      this.deleteId = id;
      this.$bvModal.show("deletePopup");
    },
    showDeleteModal(data) {
      let tempAttendanceList = [...this.attendanceList];

      if (
        this.getTimeValuesByClockInDate(tempAttendanceList, data.createdDate)
      ) {
        const targetDateTime = new Date(data.createdDate).setHours(0, 0, 0, 0);

        for (const obj of tempAttendanceList) {
          const createdDateTime = new Date(obj.clockInTime).setHours(
            0,
            0,
            0,
            0
          );
          if (createdDateTime === targetDateTime) {
            this.deleteId = obj._id;
          }
        }
      }
      this.$bvModal.show("deletePopup");
    },
    closeDeletePopUp() {
      this.$bvModal.hide("deletePopup");
      this.deleteId = null;
    },
    getTimeValuesByClockInDate(allAttendenceList, targetDate) {
      const temp = [...allAttendenceList];

      const targetDateTime = new Date(targetDate).setHours(0, 0, 0, 0);
      for (const obj of temp) {
        const createdDateTime = new Date(obj.clockInTime).setHours(0, 0, 0, 0);

        if (createdDateTime === targetDateTime) {
          const clockInDateTime = new Date(obj.clockInTime);
          if (
            obj.status == "Leave" ||
            (obj.status == "Pending Approval" &&
              obj.previousStatus == "Pending Approval")
          ) {
            return "";
          } else {
            return clockInDateTime.toLocaleTimeString();
          }
        }
        // else{
        //   return '-'
        // }
      }

      return "";
    },
    getTimeValuesByClockOutDate(allAttendenceList, targetDate) {
      const temp = [...allAttendenceList];

      const targetDateTime = new Date(targetDate).setHours(0, 0, 0, 0);

      for (const obj of temp) {
        const createdDateTime = new Date(obj.clockOutTime).setHours(0, 0, 0, 0);

        if (createdDateTime === targetDateTime) {
          const clockOutDateTime = new Date(obj.clockOutTime);
          if (
            obj.status == "Leave" ||
            (obj.status == "Pending Approval" &&
              obj.previousStatus == "Pending Approval")
          ) {
            return "";
          } else {
            return clockOutDateTime.toLocaleTimeString();
          }
          // return clockOutDateTime.toLocaleTimeString();
        }
      }

      return "";
    },
    getTimeDurationByClockInDate(allAttendenceList, targetDate) {
      const temp = [...allAttendenceList];

      const targetDateTime = new Date(targetDate).setHours(0, 0, 0, 0);
      for (const obj of temp) {
        const createdDateTime = new Date(obj.clockInTime).setHours(0, 0, 0, 0);
        if (createdDateTime === targetDateTime) {
          return new Date(obj.clockInTime);
          // return clockInDateTime.toLocaleTimeString();
        }
        // else{
        //   return '-'
        // }
      }

      return "";
    },
    getTimeDurationByClockOutDate(allAttendenceList, targetDate) {
      const temp = [...allAttendenceList];
      const targetDateTime = new Date(targetDate).setHours(0, 0, 0, 0);

      for (const obj of temp) {
        const createdDateTime = new Date(obj.clockOutTime).setHours(0, 0, 0, 0);

        if (createdDateTime === targetDateTime) {
          return new Date(obj.clockOutTime);
          //return clockOutDateTime.toLocaleTimeString();
        }
        // else{
        //   return '-'
        // }
      }

      return "";
    },
    getTimeValuesByDurationDate(clockInTime, clockOutTime) {
      if (clockInTime && clockOutTime) {
        const timeDifferenceInMilliseconds =
          new Date(clockOutTime) - new Date(clockInTime);

        // Convert milliseconds to seconds
        const seconds = Math.floor(timeDifferenceInMilliseconds / 1000);

        // Convert seconds to minutes
        const minutes = Math.floor(seconds / 60);

        // Convert minutes to hours
        const hours = Math.floor(minutes / 60);

        // Convert hours to days
        const days = Math.floor(hours / 24);
        return `${hours % 24}hrs : ${minutes % 60}min : ${seconds % 60}sec`;

        // return `Time duration: ${days} days, ${hours % 24} hours, ${minutes % 60} minutes, ${seconds % 60} seconds`
        // console.log();
      } else {
        return "";
      }
    },

    getStatusByClockInDate(allAttendenceList, targetDate) {
      let temp = [...this.attendanceList];

      const targetDateTime = new Date(targetDate).setHours(0, 0, 0, 0);

      for (const obj of temp) {
        const createdDateTime = new Date(obj.clockInTime).setHours(0, 0, 0, 0);
        if (createdDateTime === targetDateTime) {
          return obj.status;
        }
      }

      return "Absent";
    },
    getIsAbsentByClockInDate(allAttendenceList, targetDate) {
      const temp = [...allAttendenceList];

      const targetDateTime = new Date(targetDate).setHours(0, 0, 0, 0);
      for (const obj of temp) {
        const createdDateTime = new Date(obj.clockedDate).setHours(0, 0, 0, 0);
        if (createdDateTime === targetDateTime) {
          return obj.isAbsent;
        }
      }
    },
    async updateStudentAttendance() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        //this.viLoader = true;
        const response = await ViService.viXPut(
          `/contact/update/student/attendance/${this.updateAttendanceDetails._id}`,
          {
            status: this.updateAttendanceDetails.status,
          },
          userData.token
        );

        if (response.isSuccess) {
          this.closeUpdateModel();
          this.getStudentAttendanceList();
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    showUpdateModal(data) {
      this.updateAttendanceDetails = {};
      this.updateAttendanceDetails = data;
      this.$bvModal.show("updateModel");
    },
    closeUpdateModel() {
      this.updateAttendanceDetails = {};
      this.$bvModal.hide("updateModel");
    },
    async getStudentAttendanceList() {
      if (this.selectClass && this.selectSection && this.selectDate ) {
        let userData = secureUI.sessionGet("user");

        if (!userData) {
          this.$toasted.error("Please login and do the action");
        } else {
          const response = await ViService.viXGet(
            `/contact/attendance/getStudentList?class=${this.selectClass}&classroom=${this.selectSection}&search=${this.searchStudentWords}&selectedDate=${this.selectDate}&term=${this.selectTerm}`,
            userData.token
          );

          if (response.isSuccess) {
            const resultData = secureUI.secureGet(response.data);

            this.studentAttendanceList = [];
            this.studentAttendanceDetails = [];
            let studentlist = resultData.studentList;

            this.studentCount = studentlist.length;

            if (studentlist.length > 0) {
              for (let x of studentlist) {
                if (x.attendance && x.attendance.isDailyAttendance) {
                  this.studentAttendanceList.push({
                    _id: x.attendance._id,
                    term: this.selectTerm,
                    isHalfDay: x.attendance.isHalfDay || false,
                    sessionStatus: x.sessionStatus || '',
                    lastName: x.attendance.lastName,
                    firstName: x.attendance.firstName,
                    status: x.attendance.status,
                    isPresent: x.attendance.isPresent || false,
                    isDailyAttendance: x.attendance.isDailyAttendance,
                  });
                }
              }

              if (
                resultData.studentAttendanceData.length !=
                this.studentAttendanceList.length
              ) {
                this.isDailyAttendance = true;

                for (let z of studentlist) {
                  if (z.attendance) {
                    // }
                    //for(let y of resultData.studentAttendanceData){
                    this.studentAttendanceDetails.push({
                      _id: z.attendance._id,
                      firstName: z.firstName,
                      lastName: z.lastName,
                      status: z.attendance.status,
                      isPresent: z.attendance.status == "Present" ? true : false,
                      student: z._id,
                      isHalfDay: z.attendance.isHalfDay || false,
                      sessionStatus: '',
                      term: this.selectTerm,
                      class: this.selectClass,
                      classSection: this.selectSection,
                      attendanceDate: this.selectDate,
                    });
                  } else {
                    this.studentAttendanceDetails.push({
                      _id: null,
                      firstName: z.firstName,
                      lastName: z.lastName,
                      status: "Present",
                      isPresent: true,
                      isHalfDay: z.isHalfDay || false,
                      sessionStatus: '',
                      term: this.selectTerm,
                      student: z._id,
                      class: this.selectClass,
                      classSection: this.selectSection,
                      attendanceDate: this.selectDate,
                    });
                  }
                }
              } else if (
                resultData.studentAttendanceData.length == 0 &&
                this.studentAttendanceList.length == 0
              ) {
                this.isDailyAttendance = true;
                for (let z of studentlist) {
                  this.studentAttendanceDetails.push({
                    _id: null,
                    firstName: z.firstName,
                    lastName: z.lastName,
                    status: "Present",
                    isPresent: true,
                    student: z._id,
                    isHalfDay: z.isHalfDay || false,
                    sessionStatus: '',
                    term: this.selectTerm,
                    class: this.selectClass,
                    classSection: this.selectSection,
                    attendanceDate: this.selectDate,
                  });
                }
              } else {
                this.isDailyAttendance = false;
              }
            } else {
              this.isDailyAttendance = false;
            }

           
          } else {
            this.$toasted.error(response.message);
            if (response.message == "Your session has expired, please login") {
              localStorage.removeItem("user");
              this.$router.push("/login");
            }
          }
        }
      }else{
            this.studentAttendanceList = [];
            this.studentAttendanceDetails = []
      }
    },
    async getOverallAttendanceList() {
      //  if (this.selectClass && this.selectSection && this.selectDate) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const response = await ViService.viXGet(
          `/contact/attendance/getOverallAttendanceList?class=${this.overallSelectedClass}&classroom=${this.overallSelectedSection}&month=${this.overallSelectMonth}&search=${this.searchWords}&skip=${this.skip}&limit=${this.limit}&isCount=${this.countAll}`,
          userData.token
        );

        if (response.isSuccess) {
          const resultData = secureUI.secureGet(response.data);
          this.allOverallAttendanceList = resultData.studentList;

          if (Object.keys(resultData.studentList).length > 0) {
            this.lastPageNumber = Math.ceil(
              Object.keys(resultData.studentList).length / this.showLimitCount
            );
          }

          var result = Object.entries(resultData.studentList);

          let ss = result.slice(0, this.showLimitCount);

          const obj = Object.fromEntries(ss);
          this.overallAttendanceList = obj;
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },

    openAttendancePopUp() {
      this.$bvModal.show("addAttendancePopUp");
    },

    async generateandApprove() {
      let userData = secureUI.sessionGet("user");

      const response = await ViService.viXPost(
        "/contact/save/daily/attendance/student",
        this.studentAttendanceDetails,
        userData.token
      );

      if (response.isSuccess) {
        // this.$bvModal.hide("generateSection");
        this.closeAttendancePopUp();
        this.getStudentAttendanceList();
        this.$toasted.success(response.message);
      } else {
        this.$toasted.error(response.message);
        if (response.message == "Your session has expired, please login") {
          localStorage.removeItem("user");
          this.$router.push("/login");
        }
      }
    },
    async updateDailyAttendance() {
      let userData = secureUI.sessionGet("user");

      const response = await ViService.viXPost(
        "/contact/update/daily/attendance/student",
        this.studentAttendanceList,
        userData.token
      );

      if (response.isSuccess) {
        // this.$bvModal.hide("generateSection");
        this.closeAttendancePopUp();
        this.getStudentAttendanceList();
        this.$toasted.success(response.message);
      } else {
        this.$toasted.error(response.message);
        if (response.message == "Your session has expired, please login") {
          localStorage.removeItem("user");
          this.$router.push("/login");
        }
      }
    },
    closeAttendancePopUp() {
      this.$bvModal.hide("addAttendancePopUp");
    },
    async confirmDelete() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viDelete(
          `/user/attendance/${this.deleteId}`,
          userData.token
        );

        if (response.isSuccess) {
          this.$toasted.success(response.message);
          this.getStaffAttendanceList();
          this.closeDeletePopUp();
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
      this.viLoader = false;
    },
    getPreviousMonths() {
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth();

      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      for (let i = currentMonth; i >= 0; i--) {
        this.previousMonths.push({
          label: months[i],
          value: i,
        });
      }
      this.overAllPreviousMonths = [...this.previousMonths];

      this.overallSelectMonth = this.previousMonths[0].value;
      this.getOverallAttendanceList();
    },
    selectedOption(value) {
      if (value) {
        this.selectedStaff = value;
      } else {
        (this.selectedMonth = null), (this.attendanceList = []);
      }
    },
    async getStaffAttendanceList() {
      this.attendanceList = [];

      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viXPost(
          "/user/staff/admin/view/attendance/list",
          {
            skip: 0,
            limit: 1000,
            currentMonth: this.selectedMonth,
            staffId: this.selectedStaff,
          },
          userData.token
        );
        // const response = await ViService.viXGet(`/contact/getOnlineCertificateRequestDetails?studentId=${this.selectedStudent}`,userData.token);

        if (response.isSuccess) {
          this.attendanceList = secureUI.secureGet(response.data) || [];
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    getSelectedMonthAttendanceList(selectedMonth) {
      if (selectedMonth) {
        this.changeDateList(selectedMonth);
      } else {
        this.dates = [];
      }
    },
    async getStaffList(search, loading) {
      let userData = secureUI.sessionGet("user");

      if (search && search != "" && search != null && search != null) {
        const response = await ViService.viXGet(
          `/teacher/getStaffSearchList?search=${search}`,
          userData.token
        );

        // const response = await ViService.viXGet(`/common/allschool?skip=${this.skip}&limit=${this.limit}&search=${search}&isCount=${this.countAll}`);
        if (response.isSuccess) {
          const resultData = secureUI.secureGet(response.data);
          this.staffList = resultData;
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      } else {
        this.accountList = [];
        this.showOption = false;
      }
    },
    previousRoute() {
      let data = localStorage.getItem("previousTab");
      localStorage.setItem("activeTab", data);
      localStorage.setItem("previousTab", data);
      this.$router.go(-1);
    },
  },
};
</script>
